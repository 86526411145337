import {
  createApp
} from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import '/node_modules/primeflex/primeflex.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VOtpInput from "vue3-otp-input";
import {createI18n} from 'vue-i18n';
import EN from './locale/en.json';
import AR from './locale/ar.json';

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


import './assets/css/main.css';
import './assets/css/base.css';
import './assets/css/slick.css';

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";

//core
import "primevue/resources/primevue.min.css";

import 'primeicons/primeicons.css';
// import moment from 'moment'

import ToastService from 'primevue/toastservice';

import {
  createRouter,
  createWebHistory
} from 'vue-router'

import SigninView from './components/SignIn.vue'
import VerifyEmailVue from './components/VerifyEmail.vue';
import UserProfileVue from './components/UserProfile.vue';
import ProfileSettingVue from './components/ProfileSetting.vue';
import HomeView from './components/HomeView.vue'
import PlaceList from './components/PlaceList.vue'
import PlaceDetail from './components/PlaceDetail.vue';
import EventsViewVue from './components/EventsView.vue';
import EventsDetailView from './components/EventsDetailView.vue';
import AdvertiseWithUsVue from './components/AdvertiseWithUs.vue';
import AboutUsView from './components/AboutUsView.vue';
import TermsAndConditionsView from './components/TermsAndConditionsView.vue';
import PrivacyPolicyView from './components/PrivacyPolicyView.vue';
import BecomeAPartner from './components/BecomeAPartner.vue';
import ContactUsView from './components/ContactUsView.vue';
import CategoryList from './components/CategoryList.vue';
import CarouselList from './components/CarouselList.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import VerifyOtp from './components/VerifyOtp.vue';
import RedirectExternalLogin from './components/RedirectExternalLogin.vue';
import FavoriteList from './components/FavoriteList.vue';
import BookingDetails from './components/bookingDetail.vue';
import bookingSeating from './components/bookingSeating.vue'
import bookingATable from './components/bookATable.vue'
import ReservationSummary from './components/reservationSummary.vue'
import reservationThankYou from './components/reservationThankYou.vue'
import reservationReviews from './components/reservationReviews.vue'
import reservationContact from './components/reservationContact.vue'
import AddReview from './components/AddReview.vue'
import OffersView from './components/OffersView.vue';
import offerDetail from './components/OfferDetail.vue'

// New Reservation Template Components
import ReservationReserve from './components/Reservation-templates/Morphism-template/ReservationReserve.vue'

import ReservationReserveFlexible from './components/Reservation-templates/Flexible-template/ReservationReserve.vue'

import ReservationReserveInteractive from './components/Reservation-templates/Interactive-template/ReservationReserve.vue'
import ReservationReviewInteractive from './components/Reservation-templates/Interactive-template/ReservationReview.vue'

// createApp(App).mount('#app')
const app = createApp(App);

//Components
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import SelectButton from 'primevue/selectbutton';
import Menubar from 'primevue/menubar';
import Carousel from 'primevue/carousel';
import Avatar from 'primevue/avatar';
import Message from 'primevue/message';
import Rating from 'primevue/rating';
import Paginator from 'primevue/paginator';
import Galleria from 'primevue/galleria';
import ProgressBar from 'primevue/progressbar';
import Dialog from 'primevue/dialog';
import Slider from 'primevue/slider';
import Textarea from 'primevue/textarea';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import RadioButton from 'primevue/radiobutton';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu';
import Calendar from 'primevue/calendar'
import AutoComplete from 'primevue/autocomplete';
import FileUpload from 'primevue/fileupload';
import InputNumber from 'primevue/inputnumber';
import Panel from 'primevue/panel';
import Toast from 'primevue/toast';

if (localStorage.getItem('amaken_language') == null) {
  localStorage.setItem('amaken_language', 'EN');
}

const i18n = createI18n({
  locale : localStorage.getItem('amaken_language'), 
  messages : {
    EN : EN,
    AR : AR
  }
});

const router = createRouter({
  history: createWebHistory(),
  routes: [{
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        title: '',
        metaTags: [{
            name: '',
            content: ''
          },
          {
            property: '',
            content: ''
          }
        ]
      }
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: VerifyEmailVue,
      
    },
    {
      path: '/profile',
      name: 'profile',
      component: UserProfileVue,
      
    },
    {
      path: '/profile-setting',
      name: 'profile-setting',
      component: ProfileSettingVue,
      
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      
    },
    {
      path: '/signin',
      name: 'signin',
      component: SigninView,
      
    },
    {
      path: '/redirect-external-login/:id',
      name: 'redirect-external-login',
      component: RedirectExternalLogin,
      
    },
    {
      path: '/places',
      name: 'places',
      component: PlaceList,
      
    },
    {
      path: '/favorite',
      name: 'favorite',
      component: FavoriteList,
      
    },
    {
      path: '/category/:name/:id',
      name: 'category',
      component: CategoryList,
      
    },
    {
      path: '/carousel/:id',
      name: 'carousel',
      component: CarouselList,
      
    },
    {
      path: '/verifyotp/:id',
      name: 'verifyotp',
      component: VerifyOtp,
      
    },
    {
      path: '/place-detail/:id',
      name: 'place-detail',
      component: PlaceDetail,
      
    },
    {
      path: '/advertise-wit-us',
      name: 'advertise-with-us',
      component: AdvertiseWithUsVue,
      
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: AboutUsView,
      
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: TermsAndConditionsView,
      
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicyView,
      
    },
    {
      path: '/become-a-partner',
      name: 'become-a-partner',
      component: BecomeAPartner,
      
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: ContactUsView,
      
    },
    {
      path: '/events',
      name: 'events',
      component: EventsViewVue,
    },
    {
      path: '/offers',
      name: 'offers',
      component: OffersView,
    },
    {
      path: '/offer-detail/:id',
      name: 'offer-detail',
      component: offerDetail,
    },
    {
      path: '/event-detail/:id',
      name: 'event-detail',
      component: EventsDetailView,
    },
    {
      path: '/:slug/booking-details',
      name: 'booking-details',
      component: BookingDetails,
    },
    {
      path: '/:slug/booking-seating/',
      name: 'booking-seating',
      component: bookingSeating,
    },
    {
      path: '/:slug/reservation',
      name: 'reservation',
      component: bookingATable,
    },
    {
      path: '/:slug/reservation-summary',
      name: 'reservation-summary',
      component: ReservationSummary,
    },
    {
      path: '/:slug/thank-you',
      name: 'thank-you',
      component: reservationThankYou,
    },
    {
      path: '/:slug/reviews',
      name: 'reviews',
      component: reservationReviews,
    },
    {
      path: '/:slug/contact',
      name: 'contact',
      component: reservationContact,
    },
    {
      path: '/:slug/add-review/:id',
      name: 'add-review',
      component: AddReview,
    },
    {
      path: '/1/reserve',
      name: 'reservation-reserve-1',
      component: ReservationReserve,
    },
    {
      path: '/2/reserve',
      name: 'reservation-reserve-2',
      component: ReservationReserveFlexible,
    },
    {
      path: '/3/reserve',
      name: 'reservation-reserve-3',
      component: ReservationReserveInteractive,
    },
    {
      path: '/1/review',
      name: 'reservation-review-1',
      component: ReservationReviewInteractive,
    }
  ],
  scrollBehavior(to, from) {
    // scrollBehavior (to, from, savedPosition) {
      console.log('wewewe',to, from);
    if ( to.name == 'home' ||
         to.name == 'place-detail' || 
         to.name == 'places' || 
         to.name == 'profile-setting' || 
         to.name == 'carousel' || 
         to.name == 'category' || 
         to.name == 'place-detail' ||
         to.name == 'events' ||
         to.name == 'offers' ||
         to.name == 'offer-detail' ||
         to.name == 'terms-and-conditions' ||
         to.name == 'privacy-policy' ||
         to.name == 'favorite' ||
         to.name == 'become-a-partner' ||
         to.name == 'contact-us' ||
         to.name == 'booking-details' ||
         to.name == 'booking-seating' ||
         to.name == 'reservation' ||
         to.name == 'thank-you' ||
         to.name == 'reservation-summary' ||
         to.name == 'add-review' ||
         to.name == 'reservation-review-1' ||
         to.name == 'event-detail')
    {
      // if(to.name == 'places' && from.name == 'places') {
      //   window.location.href = '/places';
      // }
      return {
        top: 0
      }
    }
  }
});

app.component('button-prime', Button);
app.component('dropdown-prime', Dropdown);
app.component('inputText-prime', InputText);
app.component('checkbox-prime', Checkbox);
app.component('password-prime', Password);
app.component('selectButton-prime', SelectButton);
app.component('menubar-prime', Menubar);
app.component('carousel-prime', Carousel);
app.component('avatar-prime', Avatar);
app.component('rating-prime', Rating);
app.component('paginator-prime', Paginator);
app.component('galleria-prime', Galleria);
app.component('progressBar-prime', ProgressBar);
app.component('dialog-prime', Dialog);
app.component('slider-prime', Slider);
app.component('textarea-prime', Textarea);
app.component('tabView-prime', TabView);
app.component('tabPanel-prime', TabPanel);
app.component('radioButton-prime', RadioButton);
app.component('menu-prime', Menu);
app.component('tieredMenu-prime', TieredMenu);
app.component('message-prime', Message);
app.component('calendar-prime', Calendar);
app.component('toast-prime', Toast);
app.component('autoComplete-prime', AutoComplete);
app.component('fileupload-prime', FileUpload);
app.component('panel-prime', Panel);
app.component('inputnumber-prime', InputNumber);
app.component('v-otp-input', VOtpInput);
app.use(PrimeVue);
app.use(router);
// app.use(moment);
app.use(VueTelInput);
app.use(ToastService);
app.use(i18n);

app.config.globalProperties.amaken_language = localStorage.getItem('amaken_language');
app.config.globalProperties.external_login_facebook = (location.host == '127.0.0.1:8080' ? 'http://127.0.0.1:8000/api/auth/facebook' : 'https://admin.amakenapp.com/api/auth/facebook');
app.config.globalProperties.external_login_google = (location.host == '127.0.0.1:8080' ? 'http://127.0.0.1:8000/api/auth/google' : 'https://admin.amakenapp.com/api/auth/google');
app.config.globalProperties.api_url = (location.host == '127.0.0.1:8080' ? 'http://amaken.test/api/' : 'https://admin.amakenapp.com/api/');
app.config.globalProperties.api_url = 'https://admin.amakenapp.com/api/';
// app.config.globalProperties.api_url = 'http://127.0.0.1:8000/api/';

app.mount('#app');