<template>
    <div class="reservation-content-container-interactive">

        <reservationHeader />

        <div class="reservation-banner-area">
            <div class="bg-layer"></div>
        </div>

        <div class="reservation-logo-area">
            <!-- <img src="/src/assets/images/reservation-templates/logo-default.svg"> -->
        </div>

        <div class="reservation-content-form-element" v-if="showReservationForm">
            <div class="form-element-fields">

            </div>
        </div>

        <reservationFooter />
    </div>
</template>

<script>

</script>

<style>
.reservation-content-container-interactive {
    background-color: #F4F7FE;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 75px;
}

.reservation-content-container-interactive .reservation-banner-area {
    height: 400px;
    /* background: #e9edf6; */
    background-image: url('/src/assets/images/reservation-templates/reservation-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 90%;
    margin-bottom: -260px;
    position: relative;
}

.reservation-content-container-interactive .reservation-logo-area {
    width: 160px;
    height: 160px;
    background: #000;
    border-radius: 50%;
    margin-top: -3rem;
    margin-bottom: 30px;
    z-index: 1;
    border: 6px solid #f4f7fe;
}

.reservation-content-container-interactive .reservation-content-form-element {
    background: #fff;
    border-radius: 10px;
    width: 500px;
    min-height: 460px;
    padding: 25px;
    margin-bottom: 2rem;
    border-top: 10px solid #DD3636;
    box-shadow: 0 4px 15px rgb(0 0 0 / 7%);
}

.reservation-content-container-interactive h2.form-main-title {
    color: #000;
    font-weight: 600;
}

.reservation-content-container-interactive h2.form-main-title {
    color: #000;
    font-weight: 600;
}

.reservation-content-container-interactive label.form-group-label {
    display: block;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0.3rem;
}

@media only screen and (max-width: 767px) { 
    .reservation-content-container-interactive .reservation-content-form-element {
        width: 94% !important;
    }
    
    .reservation-content-container-interactive .reservation-successful-element {
        width: 94% !important;
    }

    .reservation-content-container-interactive .reservation-template-header-tabs {
        column-gap: 0.6rem !important;
    }
}
</style>

<script>
  import reservationHeader from '/src/components/Reservation-templates/Interactive-template/ReservationHeader.vue'
  import reservationFooter from '/src/components/Reservation-templates/Interactive-template/ReservationFooter.vue'

  export default {
    components: {
        reservationHeader,
        reservationFooter
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    mounted(){
    } 
  }
</script>